window.addEventListener("load", () => {
  $(document).on('submit', 'form#new_status_subscriber', function(e) {
    e.preventDefault();
    $.ajax({
      url: '/en/success/status/subscribe',
      type: 'POST',
      data: $(this).serialize(),
      success: function() {
        $('#status-subscriber-form-container').addClass('d-none');
        $('#status-subscriber-result-container').removeClass('d-none');
      },
      error: function() {
        alert("Invalid email address, or email address already subscribed.");
      }
    });
  });
});