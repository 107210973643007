// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
// require("turbolinks").start();
require("@rails/activestorage").start()
require("channels")
require("jquery")
require("packs/countrySelect.min")

require("bootstrap")
require("toastr")
require("select2")
require("flatpickr")


require("packs/social")
require("packs/loader")
require("packs/menu")
require("packs/slideMenu")
require("packs/toasts")
require("packs/selectInputs")
require("packs/scroll")
require("packs/ajaxContent")
require("packs/support")
require("packs/identity")
require("packs/status")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
