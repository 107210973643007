import toastr from "toastr"

window.addEventListener("load", () => {

  toastr.options = {
    'closeButton': true,
    'debug': false,
    'newestOnTop': true,
    'progressBar': false,
    'positionClass': 'toast-top-right',
    'preventDuplicates': false,
    'onclick': null,
    'showDuration': '500',
    'hideDuration': '1000',
    'timeOut': '0',
    'extendedTimeOut': '0',
    'showEasing': 'swing',
    'hideEasing': 'linear',
    'showMethod': 'fadeIn',
    'hideMethod': 'fadeOut'
  }

  document.querySelectorAll('.toast-alert').forEach(element => {
    let key = element.getAttribute('data-key')
    let title = element.getAttribute('data-title')
    let message = element.getAttribute('data-message')    
    if (key == "success") {
      toastr.success(message, title)
    }
    if (key == "notice") {
      toastr.warning(message, title)
    }
    if (key == "error") {
      toastr.error(message, title)
    }
  })

}); 