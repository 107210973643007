const checkpoint = 5;
window.addEventListener("scroll", () => {
  const currentScroll = window.pageYOffset;
  if (currentScroll >= checkpoint) {
    document.querySelector(".audata-desktop-navbar").classList.add('active')
    document.querySelector(".audata-mobile-navbar").classList.add('active')
  } else {
    document.querySelector(".audata-desktop-navbar").classList.remove('active')
    document.querySelector(".audata-mobile-navbar").classList.remove('active')
  }
});