export function showPageLoader() {
  $('#loader').addClass('active')
}
export function hidePageLoader() {
  $('#loader').removeClass('active')
}

window.onload = function afterWebPageLoad() {
  $('#loader').removeClass('active')
}

$(document).on('click', '*[data-loader="true"]', function() {
  $('#loader').addClass('active')
});