function openSlideMenu() {
  $('#dimmer').addClass('active')
  $('.audata-slide-menu').addClass('active');
}

function closeSlideMenu() {
  $('#dimmer').removeClass('active')
  $('.audata-slide-menu').removeClass('active');
}

$(document).on('click', '[data-action="openSlideMenu"]', function() {
  openSlideMenu();
  return(false);
});

$(document).on('click', '[data-action="closeSlideMenu"]', function() {
  closeSlideMenu();
  return(false);
});