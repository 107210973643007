window.addEventListener("load", () => {

  $("#country").countrySelect({
    preferredCountries: ['au', 'gb', 'us', 'nz', 'sg', 'ca', 'ae'],
    responsiveDropdown: true,
    defaultCountry: 'au'
  })

  function submitCountryInput() {
    let optionsContainer = $('#support-options-container')
    let countrySelectInput = $('#country')
    $.ajax({
      url: '/support/find',
      data: {
        country: countrySelectInput.countrySelect('getSelectedCountryData').iso2
      },
      beforeSend: function(xhr) {
        $('#loader').addClass('active')
      },
      success: function(data) {
        optionsContainer.html(data)
        const element = document.getElementById('support-scroll-anchor')
        const y = element.getBoundingClientRect().top + window.pageYOffset - 80;
        window.scrollTo({
          top: y,
          behavior: "smooth"
        })
      },
      error: function(data) {
        alert("Please check you have selected a valid country.")
      },
      complete: function() {
        $('#loader').removeClass('active')
      }
    })
    return null
  }

  $('#support-country-form').submit(function(e) {
    e.preventDefault()
    submitCountryInput()
  })

  $('#support-country-form #country').change(function(data) {
    submitCountryInput()
  })

  if($('#support-country-form').length) {
    $('.country-select > input[type="text"]').val('').focus().attr(
      'placeholder', 'Enter your country...'
    )
  }

  // Support Cases

  $('form[data-action="searchKnowledge"]').submit(function(e) {
    e.preventDefault()
    let form = $(this)
    $.ajax({
      type: 'POST',
      url: form.attr('action'),
      data: form.serialize(),
      beforeSend: function(xhr) {
        $('#loader').addClass('active')
      },
      success: function(data) {
        $('#knowledge-container').html(data)
        const element = document.getElementById('knowledge-container')
        const y = element.getBoundingClientRect().top + window.pageYOffset - 80;
        window.scrollTo({
          top: y,
          behavior: "smooth"
        })        
      },
      complete: function() {
        $('#loader').removeClass('active')
      }
    })
  })

})